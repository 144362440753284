<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-app-bar dense>
          <v-app-bar-title><v-icon>mdi-truck</v-icon>出荷指示一覧</v-app-bar-title>
          <v-spacer></v-spacer>
          <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
            <search-conditions @search="onBtnSearch" max-height="100%">
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-if="isCafereoUser"
                    label="検索上限無し"
                    v-model="searchModel.fetchLimitOverFlg"
                    dense
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-select
                    clearable
                    v-model="searchModel.shippingStatus"
                    :items="shippingStatuses"
                    label="出荷ステータス"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <dp-date-picker
                    type="date"
                    label="出荷日From"
                    v-model="searchModel.shipDateFrom"
                    dense
                    :rules="[rules.shipDateFromRule]"
                  ></dp-date-picker>
                </v-col>
                <v-col>
                  <dp-date-picker
                    type="date"
                    label="出荷日To"
                    v-model="searchModel.shipDateTo"
                    dense
                    :rules="[rules.shipDateToRule]"
                  ></dp-date-picker>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="searchModel.supplierShortName"
                    :rules="[rules.maxLength(150)]"
                    label="仕入先略称"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-textarea
                    v-if="isCafereoUser"
                    v-model="searchModel.jancode"
                    :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                    label="JANコード"
                    rows="1"
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-if="isCafereoUser"
                    v-model="searchModel.productName"
                    :rules="[rules.maxLength(150)]"
                    label="商品名"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="searchModel.customerName"
                    label="取引先名"
                    dense
                    :rules="[rules.maxLength(150)]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-if="isCafereoUser"
                    v-model="searchModel.deliveryName1"
                    :rules="[rules.maxLength(150)]"
                    label="届先法人名"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </search-conditions>
          </v-form>
          <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-menu-button
            v-if="isCafereoUser && (allowedAction(['C040602']) || allowedAction(['C040606']))"
            :disabled="this.selectionRows.length < 1"
            text="在庫移動"
            icon="mdi-transfer"
          >
            <v-list dense>
              <v-list-item
                v-if="allowedAction(['C040602'])"
                @click="onRemoveTransferClick"
                :disabled="!enabledRemoveTransfer"
                dense
              >
                <v-list-item-avatar class="ma-0"><v-icon small>mdi-pencil-remove</v-icon></v-list-item-avatar>
                <v-list-item-title>移動指示取消</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="allowedAction(['C040606'])"
                @click="onUpdateTransferClick"
                :disabled="!enabledUpdateTransfer"
                dense
              >
                <v-list-item-avatar class="ma-0"><v-icon small>mdi-pencil-box-outline</v-icon></v-list-item-avatar>
                <v-list-item-title>移動編集</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="allowedAction(['C040606'])"
                @click="onBulkUpdateTransferClick"
                :disabled="!enabledBulkUpdateTransfer"
                dense
              >
                <v-list-item-avatar class="ma-0"><v-icon small>mdi-database-edit</v-icon></v-list-item-avatar>
                <v-list-item-title>移動一括編集</v-list-item-title>
              </v-list-item>
            </v-list>
          </tooltip-icon-menu-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-button
            v-if="allowedAction(['C040603'])"
            icon="mdi-check"
            @click="onCommitTransferClick"
            :disabled="!enabledCommitTransfer"
            >CR出荷実績登録</tooltip-icon-button
          >
          <v-divider vertical></v-divider>
          <tooltip-icon-button
            v-if="allowedAction(['C040604'])"
            icon="mdi-database-arrow-down"
            @click="onDataExportClick"
            :disabled="!enabledDataExport"
            >出荷指示データ出力</tooltip-icon-button
          >
          <v-dialog v-model="dataExportDialog" max-width="1600px" persistent>
            <shipping-instruction-data-export
              :isAmazon="false"
              :inputModel="selectionRows"
              v-if="dataExportDialog"
              @cancel="dataExportDialog = false"
              @exported="onDataExportSubmit"
            ></shipping-instruction-data-export>
          </v-dialog>
          <!-- <v-divider vertical></v-divider> -->
          <!-- <tooltip-icon-button
            v-if="isCafereoUser && allowedAction(['C040606'])"
            :disabled="!enabledUpdateTransfer"
            icon="mdi-pencil-box-outline"
            @click="onUpdateTransferClick"
            >移動編集</tooltip-icon-button
          > -->
          <v-dialog v-model="updateTransferDialog" max-width="600px" persistent scrollable>
            <stock-move-edit
              :inputModel="selectedRow"
              v-if="updateTransferDialog"
              @cancel="updateTransferDialog = false"
              @updated="onUpdateTransferSubmit"
            ></stock-move-edit>
          </v-dialog>
          <!-- <tooltip-icon-button
            v-if="isCafereoUser && allowedAction(['C040606'])"
            :disabled="!enabledBulkUpdateTransfer"
            icon="mdi-database-edit"
            @click="onBulkUpdateTransferClick"
            >移動一括編集</tooltip-icon-button
          > -->
          <v-dialog v-model="updateMultipleTransfersDialog" max-width="1800px" persistent scrollable>
            <stock-move-bulk-edit
              :inputModel="selectionRows"
              v-if="updateMultipleTransfersDialog"
              @cancel="updateMultipleTransfersDialog = false"
              @updated="onBulkUpdateTransferSubmit"
            ></stock-move-bulk-edit>
          </v-dialog>
          <v-divider vertical></v-divider>
          <tooltip-icon-button
            v-if="isCafereoUser && allowedAction(['C040605'])"
            :disabled="!enabledUpdate"
            icon="mdi-database-edit"
            @click="onUpdateClick"
            >出荷指示編集</tooltip-icon-button
          >
          <v-dialog v-model="updateDialog" max-width="1800px" persistent scrollable>
            <shipping-instruction-entry
              v-if="updateDialog"
              :inputModel="selectionRows"
              @cancel="onUpdateClose"
              @updated="onUpdateClose"
            ></shipping-instruction-entry>
          </v-dialog>
          <tooltip-icon-button
            v-if="isCafereoUser && allowedAction(['C040607'])"
            :disabled="!enabledDecision"
            icon="mdi-check"
            @click="onDecisionClick"
            >出荷指示確定</tooltip-icon-button
          >
          <tooltip-icon-button
            v-if="isCafereoUser && allowedAction(['C040608'])"
            :disabled="!enabledDecisionCancel"
            icon="mdi-close"
            @click="onDecisionCancelClick"
            >出荷指示確定取消</tooltip-icon-button
          >
          <tooltip-icon-button
            v-if="isCafereoUser && allowedAction(['C040609'])"
            :disabled="!enabledInsurefixCancel"
            icon="mdi-database-edit"
            @click="onInsureFixCancelClick"
            >確保付け解除</tooltip-icon-button
          >
          <tooltip-icon-button
            v-if="enabledYodobashiOutData"
            icon="mdi-book-arrow-down"
            @click="onYodobashiShippingDataExportClick"
            >ヨドバシ出荷データ出力</tooltip-icon-button
          >
          <tooltip-icon-button v-if="allowedAction(['C040610'])" icon="mdi-download" @click="onCsvExportClick"
            >CSVダウンロード</tooltip-icon-button
          >
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="shownInfo"
            >詳細表示</tooltip-icon-toggle-button
          >
        </v-app-bar>
      </v-col>
    </v-row>
    <v-row style="height: 95%">
      <v-col :style="gridStyle" :cols="shownInfo ? 9 : 12" v-show="!shownInfo || !infoMaximum">
        <v-row>
          <v-col cols="3">
            <v-card v-if="isCafereoUser" elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                卸金額合計：￥{{ this.unitPriceTotalTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card v-if="isCafereoUser" elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                受注数合計：{{ this.orderQuantityTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-spacer></v-spacer
        ></v-row>
        <ag-grid-vue
          :gridOptions="gridOptions"
          :rowData="instructionRecords"
          class="ag-theme-alpine"
          style="height: 90%"
          @selection-changed="onSelectionChanged"
          @filter-changed="calcTotalItem"
        ></ag-grid-vue>
      </v-col>
      <v-col
        v-if="shownInfo"
        id="ShippingInstructionInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <shipping-instruction-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :shippingInstructionId="selectedRow != null ? selectedRow.shippingInstructionId : null"
        ></shipping-instruction-infos>
      </v-col>
    </v-row>
    <confirm-grid-dialog
      ref="decisionConfirmGrid"
      width="1100px"
      height="350px"
      icon="mdi-check"
      title="出荷指示確定"
      :autoClose="false"
      :columns="decisionConfirmColumns"
    ></confirm-grid-dialog>
    <confirm-grid-dialog
      ref="decisionCancelConfirmGrid"
      width="1100px"
      height="350px"
      icon="mdi-check"
      title="出荷指示確定取消"
      :autoClose="false"
      :columns="decisionCancelConfirmColumns"
    ></confirm-grid-dialog>
    <error-grid-dialog
      ref="bulkErrorGrid"
      width="80%"
      height="80%"
      icon="mdi-calendar-check"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
    <confirm-grid-dialog
      ref="insureFixCancelConfirmGrid"
      width="1100px"
      height="350px"
      icon="mdi-database-edit"
      title="確保付け解除"
      :autoClose="false"
      :columns="insureFixCancelConfirmColumns"
    ></confirm-grid-dialog>
    <confirm-grid-dialog
      ref="commitTransferConfirmGrid"
      width="1100px"
      height="350px"
      icon="mdi-check"
      title="CR出荷実績登録"
      :autoClose="false"
      :columns="commitTransferConfirmColumns"
    ></confirm-grid-dialog>
  </v-container>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconMenuButton from "../../components/common/TooltipIconMenuButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import ConfirmGridDialog from "../../components/common/ConfirmGridDialog.vue";
import CafereoShippingStatuses from "../../consts/shippingStatus/CafereoShippingStatuses";
import ShippingInstructionInfos from "./../../components/stock/ShippingInstructionInfos.vue";
import ShippingInstructionEntry from "./../../components/stock/ShippingInstructionEntry.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Storages from "../../consts/Storages";
import { corpList as CorpList } from "../../consts/CorpList";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import ShippingInstructionDataExport from "./../../components/stock/ShippingInstructionDataExport.vue";
import StockMoveEdit from "./../../components/stock/StockMoveEdit.vue";
import StockMoveBulkEdit from "./../../components/stock/StockMoveBulkEdit.vue";
import Validation from "../../libs/validation";
import { comma } from "../../filter/NumberFilter";
import RequestUtils from "../../utils/RequestUtils";

export default {
  name: "ShippingInstructionSearch",
  components: {
    AgGridVue,
    SearchConditions,
    TooltipIconButton,
    TooltipIconMenuButton,
    TooltipIconToggleButton,
    ConfirmGridDialog,
    ShippingInstructionInfos,
    ShippingInstructionEntry,
    ErrorGridDialog,
    ShippingInstructionDataExport,
    StockMoveEdit,
    StockMoveBulkEdit,
  },
  data() {
    return {
      gridStyle: { height: "95%" },
      shownInfo: false,
      infoMaximum: false,
      activeTab: null,
      selectedPageSize: 10,
      gridOptions: {
        columnTypes: {
          dpNumericColumn: NumericColumn,
          dpPercentColumn: PercentColumn,
          dpDateColumn: DateColumn,
          dpFullDateColumn: FullDateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
        },
        frameworkComponents: {
          dpBooleanFilter: BooleanFilter,
          dpSelectionFilter: SelectionFilter,
        },
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
          },
          { headerName: "伝票番号", field: "slipNo", pinned: "left" },
          { headerName: "受注ID", field: "orderId", pinned: "left" },
          {
            headerName: "出荷ステータス",
            field: "shippingStatus",
            filter: "dpSelectionFilter",
            filterParams: { options: CafereoShippingStatuses.all() },
            valueGetter: (params) => CafereoShippingStatuses.of(params.data.shippingStatus),
            pinned: "left",
          },
          { headerName: "出荷日", field: "shipDate", pinned: "left", type: "dpDateColumn" },
          { headerName: "出荷希望", field: "isReady", pinned: "left", type: "dpCheckmarkColumn" },
          { headerName: "出荷場所", field: "storageName" },
          { headerName: "JANCODE", field: "janCode" },
          { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
          { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
          {
            headerName: "Box入数",
            field: "inBoxQuantity",
            type: "dpNumericColumn",
          },
          {
            headerName: "CT中Box入数",
            field: "inCtBoxQuantity",
            type: "dpNumericColumn",
          },
          {
            headerName: "CT中pcs入数",
            field: "inCtPcsQuantity",
            type: "dpNumericColumn",
          },
          {
            headerName: "CTsizeW",
            field: "ctSizeWidth",
            type: "dpNumericColumn",
          },
          {
            headerName: "CTsizeD",
            field: "ctSizeDepth",
            type: "dpNumericColumn",
          },
          {
            headerName: "CTsizeH",
            field: "ctSizeHeight",
            type: "dpNumericColumn",
          },
          { headerName: "受注数", field: "orderQuantity", type: "dpNumericColumn" },
          {
            headerName: "掛率",
            field: "wholesaleRate",
            type: "dpPercentColumn",
            valueGetter: (params) => params.data.wholesaleRate.toFixed(1),
          },
          { headerName: "法人名", field: "corporateName" },
          { headerName: "取引先名", field: "customerName", filterParams: IncludeFilter },
          { headerName: "発売日", field: "releaseDate" },
          { headerName: "社内備考", field: "cafereoRemarks" },
          { headerName: "伝票備考", field: "slipRemarks" },
          { headerName: "アシスタント名", field: "salesAssistant" },
          { headerName: "配送CD", field: "deliveryCode" },
          { headerName: "配送先住所", field: "deliveryAddress" },
          { headerName: "届先法人名", field: "deliveryName1" },
          { headerName: " 届先名称", field: "deliveryName2", filterParams: IncludeFilter },
          {
            headerName: "移動出荷指示",
            field: "isMoving",
            type: "dpCheckmarkColumn",
            filterParams: { caption: { true: "移動出荷", false: "通常出荷" } },
          },
          {
            headerName: "カートン分割指定",
            field: "cartonNumber",
          },
          {
            headerName: "佐川指定",
            field: "useSagawa",
            type: "dpCheckmarkColumn",
            filterParams: { caption: { true: "指定あり", false: "指定なし" } },
          },
          {
            headerName: "チャーター指定",
            field: "useCharter",
            type: "dpCheckmarkColumn",
            filterParams: { caption: { true: "指定あり", false: "指定なし" } },
          },
          {
            headerName: "AM優先指定",
            field: "priorityAm",
            type: "dpCheckmarkColumn",
            filterParams: { caption: { true: "指定あり", false: "指定なし" } },
          },
          {
            headerName: "オリコン指定",
            field: "useOricon",
            type: "dpCheckmarkColumn",
            filterParams: { caption: { true: "指定あり", false: "指定なし" } },
          },
          {
            headerName: "ガチャ商品",
            field: "gachaProducts",
            type: "dpCheckmarkColumn",
            filterParams: { caption: { true: "指定あり", false: "指定なし" } },
          },
          {
            headerName: "同梱指定",
            field: "bundledDesignation",
            type: "dpCheckmarkColumn",
            filterParams: { caption: { true: "指定あり", false: "指定なし" } },
          },
          {
            headerName: "事前出荷指定(カートン出荷)",
            field: "preShipment",
            type: "dpCheckmarkColumn",
            filterParams: { caption: { true: "指定あり", false: "指定なし" } },
          },
          {
            headerName: "着払い出荷",
            field: "cashOnDelivery",
            type: "dpCheckmarkColumn",
            filterParams: { caption: { true: "指定あり", false: "指定なし" } },
          },
          {
            headerName: "要送状NO",
            field: "useShippingLabel",
            type: "dpCheckmarkColumn",
            filterParams: { caption: { true: "指定あり", false: "指定なし" } },
          },
          { headerName: "配送指定日", field: "specifiedDeliveryDate", type: "dpDateColumn" },
          {
            headerName: "配送先所要日数",
            field: "daysRequired",
            valueGetter: (params) => {
              if (params.data.daysRequired == 1) {
                return "翌日着";
              } else if (params.data.daysRequired > 1) {
                const daysParams = params.data.daysRequired - 1;
                return `中${daysParams}日着`;
              }
              return "";
            },
          },
          { headerName: "単価合計金額", field: "unitPriceTotal", type: "dpNumericColumn" },
          {
            headerName: "[配送先毎]単価合計金額",
            field: "unitPriceTotalByDeliveryAddress",
            type: "dpNumericColumn",
          },
          //{ headerName: "備考", field: "remarks" },
          { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn" },
          { headerName: "登録者", field: "createUser" },
          { headerName: "更新日時", field: "updateDatetime", type: "dpDateColumn" },
          { headerName: "更新者", field: "updateUser" },
          { headerName: "取引先専用番号", field: "partnerPurchaseNo" },
          { headerName: "取引先専用明細番号", field: "partnerDetailNo" },
          { headerName: "出荷ID", field: "shippingInstructionId", hide: true },
        ],
        getRowNodeId: (data) => {
          return data.shippingInstructionId;
        },
        rowSelection: "multiple",
        suppressCellSelection: true,
        pagination: true,
        paginationPageSize: null,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
      },
      instructionRecords: [],
      selectionRows: [],
      searchModel: {
        fetchLimitOverFlg: false,
        shippingStatus: null,
        shipDateFrom: moment().format("YYYY-MM-DD"),
        shipDateTo: moment().format("YYYY-MM-DD"),
        jancode: null,
        productName: null,
        customerName: null,
        deliveryName1: null,
        supplierShortName: null,
      },
      unitPriceTotalTotal: 0,
      orderQuantityTotal: 0,
      updateDialog: false,
      updateModel: [],
      updateTransferDialog: false,
      updateMultipleTransfersDialog: false,
      dataExportDialog: false,
      detailColumnDefs: [
        {
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
        },
        { headerName: "伝票番号", field: "slipNo", pinned: "left" },
        { headerName: "受注ID", field: "orderId", pinned: "left" },
        {
          headerName: "出荷ステータス",
          field: "shippingStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoShippingStatuses.all() },
          valueGetter: (params) => CafereoShippingStatuses.of(params.data.shippingStatus),
          pinned: "left",
        },
        { headerName: "出荷日", field: "shipDate", pinned: "left", type: "dpFullDateColumn" },
        { headerName: "出荷希望", field: "isReady", pinned: "left", type: "dpCheckmarkColumn" },
        { headerName: "出荷場所", field: "storageName" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "Box入数",
          field: "inBoxQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "CT中Box入数",
          field: "inCtBoxQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "CT中pcs入数",
          field: "inCtPcsQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "CTsizeW",
          field: "ctSizeWidth",
          type: "dpNumericColumn",
        },
        {
          headerName: "CTsizeD",
          field: "ctSizeDepth",
          type: "dpNumericColumn",
        },
        {
          headerName: "CTsizeH",
          field: "ctSizeHeight",
          type: "dpNumericColumn",
        },
        { headerName: "受注数", field: "orderQuantity", type: "dpNumericColumn" },
        {
          headerName: "掛率",
          field: "wholesaleRate",
          type: "dpPercentColumn",
          valueGetter: (params) => params.data.wholesaleRate.toFixed(1),
        },
        { headerName: "法人名", field: "corporateName" },
        { headerName: "取引先名", field: "customerName", filterParams: IncludeFilter },
        { headerName: "発売日", field: "releaseDate" },
        { headerName: "社内備考", field: "cafereoRemarks" },
        { headerName: "伝票備考", field: "slipRemarks" },
        { headerName: "アシスタント名", field: "salesAssistant" },
        { headerName: "配送CD", field: "deliveryCode" },
        { headerName: "配送先住所", field: "deliveryAddress" },
        { headerName: "届先法人名", field: "deliveryName1" },
        { headerName: " 届先名称", field: "deliveryName2" },
        {
          headerName: "移動出荷指示",
          field: "isMoving",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "移動出荷", false: "通常出荷" } },
        },
        {
          headerName: "カートン分割指定",
          field: "cartonNumber",
        },
        {
          headerName: "佐川指定",
          field: "useSagawa",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "チャーター指定",
          field: "useCharter",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "AM優先指定",
          field: "priorityAm",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "オリコン指定",
          field: "useOricon",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "ガチャ商品",
          field: "gachaProducts",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "同梱指定",
          field: "bundledDesignation",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "事前出荷指定(カートン出荷)",
          field: "preShipment",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "着払い出荷",
          field: "cashOnDelivery",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        {
          headerName: "要送状NO",
          field: "useShippingLabel",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "指定あり", false: "指定なし" } },
        },
        { headerName: "配送指定日", field: "specifiedDeliveryDate", type: "dpFullDateColumn" },
        {
          headerName: "配送先所要日数",
          field: "daysRequired",
          valueGetter: (params) => {
            if (params.data.daysRequired == 1) {
              return "翌日着";
            } else if (params.data.daysRequired > 1) {
              const daysParams = params.data.daysRequired - 1;
              return `中${daysParams}日着`;
            }
            return "";
          },
        },
        //{ headerName: "備考", field: "remarks" },
        { headerName: "登録日時", field: "createDatetime", type: "dpFullDateColumn" },
        { headerName: "登録者", field: "createUser" },
        { headerName: "更新日時", field: "updateDatetime", type: "dpFullDateColumn" },
        { headerName: "更新者", field: "updateUser" },
        { headerName: "取引先専用番号", field: "partnerPurchaseNo" },
        { headerName: "取引先専用明細番号", field: "partnerDetailNo" },
      ],
      validSeearchForm: null,
      rules: {
        isJancodeSearchList: Validation.isJancodeSearchList,
        shipDateFromRule: (value) => this.shipDateFromRules(value),
        shipDateToRule: (value) => this.shipDateToRules(value),
        maxLength: Validation.maxLength,
      },
      decisionConfirmColumns: [
        { headerName: "伝票番号", field: "slipNo" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "出荷数量", field: "orderQuantity", type: "dpNumericColumn" },
        { headerName: "届先法人名", field: "deliveryName1" },
        { headerName: " 届先名称", field: "deliveryName2" },
      ],
      decisionCancelConfirmColumns: [
        { headerName: "伝票番号", field: "slipNo" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "出荷数量", field: "orderQuantity", type: "dpNumericColumn" },
        { headerName: "届先法人名", field: "deliveryName1" },
        { headerName: " 届先名称", field: "deliveryName2" },
      ],
      insureFixCancelConfirmColumns: [
        { headerName: "伝票番号", field: "slipNo" },
        { headerName: "受注ID", field: "orderId" },
        {
          headerName: "出荷ステータス",
          field: "shippingStatus",
          valueGetter: (params) => CafereoShippingStatuses.of(params.data.shippingStatus),
        },
        { headerName: "出荷日", field: "shipDate", type: "dpDateColumn" },
        { headerName: "出荷希望", field: "isReady", type: "dpCheckmarkColumn" },
        { headerName: "出荷場所", field: "storageName" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "受注数", field: "orderQuantity", type: "dpNumericColumn" },
        { headerName: "配送CD", field: "deliveryCode" },
        { headerName: "配送先住所", field: "deliveryAddress" },
        { headerName: "届先法人名", field: "deliveryName1" },
        { headerName: " 届先名称", field: "deliveryName2" },
        {
          headerName: "移動出荷指示",
          field: "isMoving",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "カートン分割指定",
          field: "cartonNumber",
        },
        {
          headerName: "佐川指定",
          field: "useSagawa",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "チャーター指定",
          field: "useCharter",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "AM優先指定",
          field: "priorityAm",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "オリコン指定",
          field: "useOricon",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "ガチャ商品",
          field: "gachaProducts",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "同梱指定",
          field: "bundledDesignation",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "事前出荷指定(カートン出荷)",
          field: "preShipment",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "着払い出荷",
          field: "cashOnDelivery",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "要送状NO",
          field: "useShippingLabel",
          type: "dpCheckmarkColumn",
        },
        { headerName: "配送指定日", field: "specifiedDeliveryDate", type: "dpDateColumn" },
        //{ headerName: "備考", field: "remarks" },
        { headerName: "伝票備考", field: "slipRemarks" },
      ],
      commitTransferConfirmColumns: [
        { headerName: "伝票番号", field: "slipNo" },
        { headerName: "受注ID", field: "orderId" },
        {
          headerName: "出荷ステータス",
          field: "shippingStatus",
          valueGetter: (params) => CafereoShippingStatuses.of(params.data.shippingStatus),
        },
        { headerName: "出荷日", field: "shipDate", type: "dpDateColumn" },
        { headerName: "出荷希望", field: "isReady", type: "dpCheckmarkColumn" },
        { headerName: "出荷場所", field: "storageName" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "受注数", field: "orderQuantity", type: "dpNumericColumn" },
        { headerName: "配送CD", field: "deliveryCode" },
        { headerName: "配送先住所", field: "deliveryAddress" },
        { headerName: "届先法人名", field: "deliveryName1" },
        { headerName: " 届先名称", field: "deliveryName2" },
        {
          headerName: "移動出荷指示",
          field: "isMoving",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "カートン分割指定",
          field: "cartonNumber",
        },
        {
          headerName: "佐川指定",
          field: "useSagawa",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "チャーター指定",
          field: "useCharter",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "AM優先指定",
          field: "priorityAm",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "オリコン指定",
          field: "useOricon",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "ガチャ商品",
          field: "gachaProducts",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "同梱指定",
          field: "bundledDesignation",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "事前出荷指定(カートン出荷)",
          field: "preShipment",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "着払い出荷",
          field: "cashOnDelivery",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "要送状NO",
          field: "useShippingLabel",
          type: "dpCheckmarkColumn",
        },
        { headerName: "配送指定日", field: "specifiedDeliveryDate", type: "dpDateColumn" },
      ],
      errorColmuns: [
        { headerName: "出荷ID", field: "shippingInstructionId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
    };
  },
  filters: { comma },
  watch: {
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
  },
  mounted() {
    this.onSearchClick();
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize + "px";
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails2(this.selectedRow.shippingInstructionId, this.detailColumnDefs, this.gridOptions.api);
    },
    enabledRemoveTransfer() {
      return this.selectedRow && this.selectedRow.isMoving && this.allowedAction(["C040602"]);
    },
    enabledCommitTransfer() {
      if (!this.allowedAction(["C040603"])) return false;
      return this.selectionRows.every(
        (row) => row.shippingStatus === CafereoShippingStatuses.CONFIRMED && row.storageCode == Storages.CAFEREO
      );
    },
    enabledDataExport() {
      return this.allowedAction(["C040604"]);
    },
    enabledUpdateTransfer() {
      if (!this.allowedAction(["C040606"])) return false;
      if (this.selectionRows.length === 1) {
        return this.selectionRows.every(
          (row) =>
            (row.orderId === "" || row.orderId === null) && (row.returnCommitId === "" || row.returnCommitId === null)
        );
      }
      return false;
    },
    enabledBulkUpdateTransfer() {
      if (!this.allowedAction(["C040606"])) return false;
      if (this.selectionRows.length > 0) {
        return this.selectionRows.every(
          (row) =>
            (row.orderId === "" || row.orderId === null) && (row.returnCommitId === "" || row.returnCommitId === null)
        );
      }
      return false;
    },
    enabledUpdate() {
      if (!this.allowedAction(["C040605"])) return false;
      if (this.selectionRows.length === 0) return false;
      return this.selectionRows.every(
        (row) =>
          (row.orderId !== "" && row.orderId !== null) || (row.returnCommitId !== "" && row.returnCommitId !== null)
      );
    },
    enabledDecision() {
      if (!this.allowedAction(["C040607"])) return false;
      if (this.selectionRows.length === 0) return false;
      return this.selectionRows.every((row) => row.isReady && row.shippingStatus === CafereoShippingStatuses.WAITING);
    },
    enabledDecisionCancel() {
      if (!this.allowedAction(["C040608"])) return false;
      if (this.selectionRows.length === 0) return false;
      return this.selectionRows.every((row) => row.shippingStatus === CafereoShippingStatuses.CONFIRMED);
    },
    enabledInsurefixCancel() {
      if (!this.allowedAction(["C040609"])) return false;
      if (this.selectionRows.length === 0) return false;
      return this.selectionRows.every(
        (row) => !row.isMoving && (row.returnCommitId === "" || row.returnCommitId === null) && !row.makerPropostion
      );
    },
    enabledYodobashiOutData() {
      return this.allowedAction(["C040611"]);
    },
    enabledCsvExport() {
      return this.allowedAction(["C040610"]);
    },
    shippingStatuses() {
      var shippingStatuses = CafereoShippingStatuses.all();
      return shippingStatuses;
    },
  },
  methods: {
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        const params = {
          fetchLimitOverFlg: this.searchModel.fetchLimitOverFlg,
          shippingStatus: this.searchModel.shippingStatus,
          shipDateFrom: this.searchModel.shipDateFrom,
          shipDateTo: this.searchModel.shipDateTo,
          jancode: this.searchModel.jancode
            ? this.searchModel.jancode
                .replaceAll(/\n+/g, " ")
                .split(" ")
                .filter((n) => n)
            : null,
          productName: this.searchModel.productName,
          customerName: this.searchModel.customerName,
          deliveryName1: this.searchModel.deliveryName1,
          supplierShortName: this.searchModel.supplierShortName,
        };
        const response = await this.$store.dispatch("shippingInstruction/search", params);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        // 配送先毎単価合計金額リストを作成
        const unitPriceTotalByDeliveryAddressList = [];
        if (response.data.contents.shipments) {
          response.data.contents.shipments.forEach((item) => {
            if (unitPriceTotalByDeliveryAddressList[item.deliveryAddress]) {
              unitPriceTotalByDeliveryAddressList[item.deliveryAddress] += item.unitPriceTotal;
            } else {
              unitPriceTotalByDeliveryAddressList[item.deliveryAddress] = item.unitPriceTotal;
            }
          });
          // 配送先の単価合計金額をリストから取得
          response.data.contents.shipments.forEach((item) => {
            const unitPriceTotalByDeliveryAddress = unitPriceTotalByDeliveryAddressList[item.deliveryAddress];
            if (unitPriceTotalByDeliveryAddress) {
              item.unitPriceTotalByDeliveryAddress = unitPriceTotalByDeliveryAddress;
            }
          });
        }
        var result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "出荷指示一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.shipments).length === 0) {
          this.$dialog.warning({
            title: "出荷指示一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridOptions.api.setRowData(result.shipments);
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => column.colId);
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
        this.calcTotalItem();
      } catch (error) {
        console.error("ShippingInstructionSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onUpdateClick() {
      this.updateDialog = true;
    },
    onUpdateClose(updateRecords) {
      this.selectionRows.forEach((row) => {
        updateRecords.forEach((record) => {
          if (record.shippingInstructionId == row.shippingInstructionId) {
            row.shipDate = record.shipDate;
            row.isReady = record.isReady;
            row.cartonNumber = record.cartonNumber;
            row.useSagawa = record.useSagawa;
            row.useCharter = record.useCharter;
            row.priorityAm = record.priorityAm;
            row.useOricon = record.useOricon;
            row.gachaProducts = record.gachaProducts;
            row.bundledDesignation = record.bundledDesignation;
            row.preShipment = record.preShipment;
            row.cashOnDelivery = record.cashOnDelivery;
            row.useShippingLabel = record.useShippingLabel;
            row.specifiedDeliveryDate = record.specifiedDeliveryDate;
            //row.remarks = record.remarks;
            row.slipRemarks = record.slipRemarks;
            row.shippingStatus = record.shippingStatus;
            row.updateDatetime = record.updateDatetime;
            row.updateUser = record.updateUser;
          }
        });
      });
      this.gridOptions.api.applyTransaction({ update: this.selectionRows });
      this.updateDialog = false;
    },
    onUpdateTransferClick() {
      this.updateTransferDialog = true;
    },
    onUpdateTransferSubmit(shipmentRecord) {
      this.selectedRow.shipDate = shipmentRecord.shipDate;
      this.selectedRow.isReady = shipmentRecord.isReady;
      this.selectedRow.storageName = shipmentRecord.storageName;
      this.selectedRow.orderQuantity = shipmentRecord.orderQuantity;
      this.selectedRow.remarks = shipmentRecord.remarks;
      this.selectedRow.updateDatetime = shipmentRecord.updateDatetime;
      this.selectedRow.updateUser = shipmentRecord.updateUser;
      this.selectedRow.movingWarehouseCode = shipmentRecord.movingWarehouseCode;
      this.gridOptions.api.applyTransaction({ update: [this.selectedRow] });
      this.updateTransferDialog = false;
    },
    onUpdateTransferCancel() {
      this.updateTransferDialog = false;
    },
    onBulkUpdateTransferClick() {
      this.updateMultipleTransfersDialog = true;
    },
    onBulkUpdateTransferSubmit(updateRecords) {
      this.selectionRows.forEach((row) => {
        updateRecords.forEach((record) => {
          if (record.shippingInstructionId == row.shippingInstructionId) {
            row.shipDate = record.shipDate;
            row.isReady = record.isReady;
            row.orderQuantity = record.orderQuantity;
            row.specifiedDeliveryDate = record.specifiedDeliveryDate;
            row.updateDatetime = record.updateDatetime;
            row.updateUser = record.updateUser;
          }
        });
      });
      this.gridOptions.api.applyTransaction({ update: this.selectionRows });
      this.updateMultipleTransfersDialog = false;
    },
    async onRemoveTransferClick() {
      let messageText = `選択された移動出荷指示を取消します`;
      const ok = await this.$dialog.confirm({ title: "キャンセル", text: messageText });
      if (ok) {
        try {
          this.loadingOn();

          var requestContents = {
            shippingInstructionId: this.selectedRow.shippingInstructionId,
            updateDatetime: this.selectedRow.updateDatetime,
          };
          const result = await this.$store.dispatch("transfer/cancel", requestContents);

          let error = result.data?.header;
          var errorRows = [];

          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.gridOptions.api.applyTransaction({ remove: [this.selectedRow] });
              this.$dialog.notify.info(`移動指示を削除しました`, { timeout: 2300 });
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push(error.messages[key]);
                });
              }
              this.$dialog.warning({
                title: `出荷指示一覧`,
                text: errorRows.join("<br>"),
                actions: ["OK"],
              });
              this.$dialog.notify.error(`移動指示の削除に失敗しました `, { timeout: 2300 });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("ShippingInstructionSearch::onRemoveTransferClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    onCommitTransferClick() {
      this.$refs.commitTransferConfirmGrid.open({
        records: this.selectionRows,
        subtitle: `全: ${this.selectionRows.length} 件`,
        onSubmit: async () => {
          try {
            this.loadingOn();
            let updateModels = [];
            this.selectionRows.forEach((row) => {
              updateModels.push({
                shippingInstructionId: row.shippingInstructionId,
                updateDatetime: row.updateDatetime,
              });
            });
            const response = await this.$store.dispatch("shipmentAchievement/setResult", { shipments: updateModels });
            let error = response.data?.header;
            // 一覧更新レコード
            let updateRows = [];
            let errorRows = [];
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      shippingInstructionId: key,
                      errorMessage: error.messages[key],
                    });
                  });
                }
                // 一覧部の更新分のレコード変更
                if (response.data.contents) {
                  this.selectionRows.forEach((row) => {
                    response.data.contents.shipmentAchievements.forEach((shipment) => {
                      let isError = errorRows.some((r) => r.shippingInstructionId === row.shippingInstructionId);
                      if (shipment.shippingInstructionId == row.shippingInstructionId && !isError) {
                        row.shippingStatus = shipment.shippingStatus;
                        row.updateDatetime = shipment.updateDatetime;
                        row.updateUser = shipment.updateUser;
                        updateRows.push(row);
                      }
                    });
                  });
                }

                // 一覧部のレコードを削除
                if (updateRows.length > 0) this.gridOptions.api.applyTransaction({ update: updateRows });
                // エラー表示
                if (errorRows.length > 0) {
                  // 確認ダイアログから更新完了分のデータを削除する
                  this.$refs.commitTransferConfirmGrid.gridOptions.api.applyTransaction({ remove: updateRows });
                  this.$refs.bulkErrorGrid.open({ title: "CR出荷実績登録", records: errorRows });
                  this.$dialog.notify.error(`在庫移動の確定処理に失敗したデータが存在します。ご確認ください。`, {
                    timeout: 2300,
                  });
                } else {
                  this.$dialog.notify.info(`在庫の移動を確定しました。`, { timeout: 2300 });
                  this.$refs.commitTransferConfirmGrid.close();
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("ShippingInstructionSearch::onInsureFixCancelClick", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    onDataExportClick() {
      let shippingStatusErrorFlg = false;
      let includeAmazonErrorFlg = false;
      this.selectionRows.forEach((row) => {
        if (row.shippingStatus !== 3) {
          shippingStatusErrorFlg = true;
        }
        if (row.corporationCd == CorpList.AMAZON) {
          includeAmazonErrorFlg = true;
        }
      });
      if (shippingStatusErrorFlg) {
        this.$dialog.notify.error(`出荷確定のデータのみ選択するか未選択としてください。`, { timeout: 2300 });
        return;
      }
      if (includeAmazonErrorFlg) {
        this.$dialog.notify.error(`Amazonの出荷データは選択できません。`, { timeout: 2300 });
        return;
      }

      this.dataExportDialog = true;
    },
    onDataExportSubmit(exportFilePath) {
      this.dataExportDialog = false;
      if (exportFilePath != null && exportFilePath != "") {
        window.open(exportFilePath, "_blank");
      }
    },
    onDecisionClick() {
      this.$refs.decisionConfirmGrid.open({
        records: this.selectionRows,
        subtitle: `全: ${this.selectionRows.length} 件`,
        onSubmit: async () => {
          try {
            this.loadingOn();
            let updateModels = [];
            this.selectionRows.forEach((row) => {
              updateModels.push({
                shippingInstructionId: row.shippingInstructionId,
                updateDatetime: row.updateDatetime,
              });
            });
            // 最大件数チェック
            const validDataSizeRet = await RequestUtils.validDataSize("C040607", updateModels.length, (limitSize) => {
              this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
                timeout: 2300,
              });
              this.loadingOff();
            });
            if (!validDataSizeRet) {
              return;
            }
            let exec = true;
            let headers = null;
            while (exec) {
              await new Promise((resolve) => setTimeout(resolve, 1000));
              let response = await this.$store.dispatch("shippingInstruction/confirm", {
                contents: updateModels,
                header: headers,
              });
              let error = response.data?.header;
              // 一覧更新レコード
              let updateRows = [];
              let errorRows = [];
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                case ApiStatus.consts.BUSINESS_ERROR:
                case ApiStatus.consts.ALREADY_CHANGED:
                  // エラーメッセージ格納
                  exec = false;
                  if (error.messages) {
                    Object.keys(error.messages).forEach((key) => {
                      errorRows.push({
                        shippingInstructionId: key,
                        errorMessage: error.messages[key],
                      });
                    });
                  }
                  // 一覧部の更新分のレコード変更
                  if (response.data.contents) {
                    this.selectionRows.forEach((row) => {
                      response.data.contents.shipments.forEach((shipment) => {
                        let isError = errorRows.some((r) => r.shippingInstructionId === row.shippingInstructionId);
                        if (shipment.shippingInstructionId == row.shippingInstructionId && !isError) {
                          row.slipNo = shipment.slipNo;
                          row.shippingStatus = shipment.shippingStatus;
                          row.updateDatetime = shipment.updateDatetime;
                          row.updateUser = shipment.updateUser;
                          updateRows.push(row);
                        }
                      });
                    });
                  }

                  // 一覧部のレコードを更新
                  if (updateRows.length > 0) this.gridOptions.api.applyTransaction({ update: updateRows });
                  // エラー表示
                  if (errorRows.length > 0) {
                    // 確認ダイアログから更新完了分のデータを削除する(画面閉じる？)
                    this.$refs.decisionConfirmGrid.gridOptions.api.applyTransaction({ remove: updateRows });
                    this.$refs.bulkErrorGrid.open({ title: "出荷指示確定", records: errorRows });
                    this.$dialog.notify.error(`出荷指示の確定処理に失敗したデータが存在します。ご確認ください。`, {
                      timeout: 2300,
                    });
                  } else {
                    this.$dialog.notify.info(`出荷指示を確定しました`, { timeout: 2300 });
                    this.$refs.decisionConfirmGrid.close();
                  }
                  break;
                case ApiStatus.consts.PROCESSING:
                  headers = { InvokeId: response.data.header.invokeId };
                  break;
                default:
                  this.redirectError();
                  exec = false;
                  break;
              }
            }
          } catch (error) {
            console.error("ShippingInstructionSearch::onDecisionClick", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    onDecisionCancelClick() {
      this.$refs.decisionCancelConfirmGrid.open({
        records: this.selectionRows,
        subtitle: `全: ${this.selectionRows.length} 件`,
        onSubmit: async () => {
          try {
            this.loadingOn();
            let updateModels = [];
            this.selectionRows.forEach((row) => {
              updateModels.push({
                shippingInstructionId: row.shippingInstructionId,
                updateDatetime: row.updateDatetime,
              });
            });
            const response = await this.$store.dispatch("shippingInstruction/cancel", { shipments: updateModels });
            let error = response.data?.header;
            // 一覧更新レコード
            let updateRows = [];
            let errorRows = [];
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      shippingInstructionId: key,
                      errorMessage: error.messages[key],
                    });
                  });
                }
                // 一覧部の更新分のレコード変更
                if (response.data.contents) {
                  this.selectionRows.forEach((row) => {
                    response.data.contents.shipments.forEach((shipment) => {
                      let isError = errorRows.some((r) => r.shippingInstructionId === row.shippingInstructionId);
                      if (shipment.shippingInstructionId == row.shippingInstructionId && !isError) {
                        row.slipNo = shipment.slipNo;
                        row.shippingStatus = shipment.shippingStatus;
                        row.updateDatetime = shipment.updateDatetime;
                        row.updateUser = shipment.updateUser;
                        updateRows.push(row);
                      }
                    });
                  });
                }
                // 一覧部のレコードを更新
                if (updateRows.length > 0) this.gridOptions.api.applyTransaction({ update: updateRows });
                // エラー表示
                if (errorRows.length > 0) {
                  // 確認ダイアログから更新した分を削除(エラーがある場合のみ)
                  this.$refs.decisionCancelConfirmGrid.gridOptions.api.applyTransaction({ remove: updateRows });
                  this.$refs.bulkErrorGrid.open({ title: "出荷指示確定取消", records: errorRows });
                  this.$dialog.notify.error(`出荷指示の確定取消処理に失敗したデータが存在します。ご確認ください。`, {
                    timeout: 2300,
                  });
                } else {
                  this.$dialog.notify.info(`出荷指示の確定を取消しました`, { timeout: 2300 });
                  this.$refs.decisionCancelConfirmGrid.close();
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("ShippingInstructionSearch::onDecisionCancelClick", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    async onYodobashiShippingDataExportClick() {
      let messageText = `選択されたデータのヨドバシ出荷データを出力します。`;
      const ok = await this.$dialog.confirm({ title: "ヨドバシ出荷データ出力", text: messageText });
      if (ok) {
        try {
          this.loadingOn();
          const rows = this.gridOptions.api.getSelectedRows();
          const ids = [];
          rows.forEach((row) => {
            ids.push(row.shippingInstructionId);
          });

          var requestContents = {
            shippingInstructIds: ids,
          };
          const result = await this.$store.dispatch("yodobashi/outShippingData", requestContents);

          let error = result.data?.header;

          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              window.open(result.data.contents.exportFilePath, "_blank");
              this.$dialog.notify.info(`ヨドバシ出荷データを出力しました`, { timeout: 2300 });
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              var messages = [];
              var message = "";
              Object.keys(error.messages).forEach(function (key) {
                messages.push(error.messages[key]?.join("<br>"));
              });
              if (messages.length > 0) message = messages.join("<br>");
              if (message != "") {
                this.$dialog.warning({
                  title: `ヨドバシ出荷データ出力`,
                  text: message,
                  actions: ["OK"],
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("ShippingInstructionSearch::onYodobashiShippingDataExportClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    onCsvExportClick() {
      var allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridOptions.api.exportDataAsCsv({
        columnKeys: allColumnIds,
        onlySelected: this.selectionRows.length > 0,
        fileName: `出荷指示一覧.csv`,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onInsureFixCancelClick() {
      this.$refs.insureFixCancelConfirmGrid.open({
        records: this.selectionRows,
        subtitle: `全: ${this.selectionRows.length} 件`,
        onSubmit: async () => {
          try {
            this.loadingOn();
            let updateModels = [];
            this.selectionRows.forEach((row) => {
              updateModels.push({
                shippingInstructionId: row.shippingInstructionId,
                orderId: row.orderId,
                lastUpdateDatetime: row.updateDatetime,
              });
            });
            const response = await this.$store.dispatch("insure/fixCancel", { orders: updateModels });
            let error = response.data?.header;
            // 一覧更新レコード
            let updateRows = [];
            let errorRows = [];
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      shippingInstructionId: key,
                      errorMessage: error.messages[key],
                    });
                  });
                } else {
                  updateRows = this.selectionRows;
                }

                // 一覧部の更新分のレコード変更
                if (response.data.contents) {
                  // 登録に成功したデータ(エラーにないデータ)を抽出
                  this.selectionRows.forEach((row) => {
                    let isError = this.errorRows.some((r) => r.shippingInstructionId === row.shippingInstructionId);
                    if (!isError) {
                      this.updateSuccessRecords.push(row.data);
                      updateRows.push(row);
                    }
                  });
                }

                // 一覧部のレコードを削除
                if (updateRows.length > 0) this.gridOptions.api.applyTransaction({ remove: updateRows });
                // エラー表示
                if (errorRows.length > 0) {
                  // 確認ダイアログから更新完了分のデータを削除する
                  this.$refs.insureFixCancelConfirmGrid.gridOptions.api.applyTransaction({ remove: updateRows });
                  this.$refs.bulkErrorGrid.open({ title: "確保付け解除", records: errorRows });
                  this.$dialog.notify.error(`確保付け解除処理に失敗したデータが存在します。ご確認ください。`, {
                    timeout: 2300,
                  });
                } else {
                  this.$dialog.notify.info(`受注の確保付けを解除しました`, { timeout: 2300 });
                  this.$refs.insureFixCancelConfirmGrid.close();
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("ShippingInstructionSearch::onInsureFixCancelClick", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    shipDateFromRules(value) {
      if (value == null || this.searchModel.shipDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.shipDateTo)) return "出荷日Toより前の日付を指定してください";
      return true;
    },
    shipDateToRules(value) {
      if (value == null || this.searchModel.shipDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.shipDateFrom)) return "出荷日Fromより後の日付を指定してください";
      return true;
    },
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    calcTotalItem() {
      this.unitPriceTotalTotal = 0;
      this.orderQuantityTotal = 0;
      let rowData = [];
      this.gridOptions.api.forEachNodeAfterFilter((node) => rowData.push(node.data));
      for (let row of rowData) {
        this.unitPriceTotalTotal += row.unitPriceTotal ? row.unitPriceTotal : 0;
        this.orderQuantityTotal += row.orderQuantity ? row.orderQuantity : 0;
      }
    },
  },
};
</script>
